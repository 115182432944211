.playerName {
  color: rgb(118, 190, 210);
  font-size: 1.5em;
  padding: 0.5em;
}

.bg-dark-grey {
  background-color: #213221;
}

.green-border {
  border: solid thick rgb(0, 117, 0);
  border-radius: 8px;
  padding: 15px;
}