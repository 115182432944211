.picture-box {
  height: calc(100px + 1em);
  margin-bottom: 1em;
  padding: 0.5em;
  background: rgb(85, 0, 0);
  border-radius: 5px;
}

.quote-box {
  margin-top: 1em;
  padding: 0.5em;
  border-radius: 5px;
  background: aquamarine;
}

.quote {
  font-style: italic;
}

.author {
  font-weight: bold;
  text-align: right;
}

.author::before {
  content: " - ";
}

#picture img {
  height: 100%;
  width: 100%;
}

/* snow background */
#snow {
  background-color: #000000;
  background-image: url('https://www.transparenttextures.com/patterns/snow.png');
  animation: snowflakes 20s linear infinite;
}

@keyframes snowflakes {
  0% {
    background-position: 0 0, 0 0, 0 0;
  }
  100% {
    background-position: 0 1000px, 0 800px, 0 1200px;
  }
}
